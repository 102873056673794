
import React from 'react'
import { useParams } from 'react-router-dom'

import './redirect_to_app.css'

import { Navbar, Footer } from '../../components'

import invite_care_circle from '../../assets/invite_care_circle.webp'

const RedirectToApp = () => { // Page to redirect to the app scheme for deep linking
    const wildcard = useParams()["*"]
    const url = `hibi://invite/${wildcard}` // Previously utilised link for app download: https://onelink.to/gn5zbu

    React.useEffect(() => {
        window.location.href = url
    }, [url])
  
    return ( 
        <div className='RedirectToApp'>
            <Navbar /> 
            <div className='redirect_to_app'>
                <img
                    loading='lazy'
                    className='invite-care-circle-image'
                    src={invite_care_circle}
                    alt='Invitation to join care circle'
                    width={600}
                />
                <h3>Redirecting you to the Hibi app...</h3> 
                <p>This should only take a few seconds. If this does not work, please make sure that the <a href='https://onelink.to/gn5zbu'>Hibi app</a> is installed on your device and try again.</p> 
            </div>
            <Footer />
        </div>
    )
}

export default RedirectToApp