import React, { useState } from 'react';
import './quiz_form.css';

import close from '../../assets/discard_button_with_bg.png';
import hibi_plus_promotion from '../../assets/hibi_plus_promotion.webp'

const QuizForm = ({ stripeLink, setShowQuiz = () => {} }) => {
  const questions = [
    {
        id: 1,
        question: 'Do you care for a child with developmental differences and health conditions?',
        isFreeText: false,
        options: ['Yes', 'No'],
    },
    {
        id: 2,
        question: 'Do they have a diagnosis or are you waiting for one?',
        isFreeText: false,
        options: ['Yes', 'No'],
    },
    {
        id: 3,
        question: 'What is the (suspected) diagnosis?',
        isFreeText: true,
        options: [],
    },
    {
        id: 4,
        question: 'Are you involved in their care?',
        isFreeText: false,
        options: ['Yes', 'No'],
    },
    {
        id: 5,
        isEnd: true,
    }
  ];

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [diagnosis, setDiagnosis] = useState('');
  const [progress, setProgress] = useState(0)

  const handleOptionSelect = (questionId, option) => {
    setSelectedOptions((prevOptions) => ({ ...prevOptions, [questionId]: option }));
    
    // Compute and set new progress if applicable
    const newProgress = ((currentQuestionIndex + 1) / questions.length) * 100;
    newProgress > progress && setProgress(newProgress)

    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }, 750);
  };

  const handlePrevQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const ProgressBar = () => {
    return (
      <div className="progress">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
    );
  };

  const renderQuestion = () => {
    const question = questions[currentQuestionIndex];

    if (question.isEnd) {
        return (
            <div className="question-container">
            <div className='question-container-header'>         
                <p className='question-container-title'>Hibi Plus is built for <span style={{ fontFamily: 'GTSectraItalic' }}>you</span> and <span style={{ fontFamily: 'GTSectraItalic' }}>your family</span> 💚</p>
                <ProgressBar />
            </div>
            <div className='question-container-user-input-container' style={{ flexDirection: 'column' }}>
                <img style={{ width: '100%', maxWidth: 428, height: 'auto', marginTop: 24, marginBottom: 48, borderRadius: 12 }} src={hibi_plus_promotion} alt='Hibi Plus Promotion' />
                <button
                    className={`quiz-form-cta-button`}
                    onClick={() => {
                        window.open(stripeLink, "_blank", "noreferrer");
                        setProgress(100);
                    }}
                >
                    Try Hibi+
                </button>
            </div>
          </div>
        );
    }

    if (question.isFreeText) {
      return (
        <div className="question-container">
          <div className='question-container-header'>         
            <p className='question-container-title'>{question.question}</p>
            <ProgressBar />
          </div>
          <div className='question-container-user-input-container wrapped-around-container'>
            <input
                className='quiz-input'
                type="text"
                placeholder="Type something..."
                onChange={(e) => setDiagnosis(e.target.value) }
            />
            <button
                className={`options-container-button ${selectedOptions[question.id] ? 'options-container-button-active' : ''}`}
                style={{ marginTop: -11, paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0, width: 64, maxWidth: 64, minWidth: 64, alignSelf: 'center', justifySelf: 'center' }}
                onClick={() => handleOptionSelect(question.id, diagnosis)}
            >
                Next
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="question-container">
        <div className='question-container-header'>         
            <p className='question-container-title'>{question.question}</p>
            <ProgressBar />
        </div>
        <div className='question-container-user-input-container'>
            <div className="options-container">
            {question.options.map((option) => (
                <button
                    className={`options-container-button ${option === selectedOptions[question.id] ? 'options-container-button-active' : ''}`}
                    key={option}
                    onClick={() => handleOptionSelect(question.id, option)}
                >
                    {option}
                </button>
            ))}
            </div>
        </div>
      </div>
    );
  };

  return (
    <div className="quiz-form-container">
      <div id="qc_embed_shell">
        <img
          style={{
            position: 'absolute',
            top: 8,
            right: 8,
            width: 36,
            height: 36,
            cursor: 'pointer',
          }}
          onClick={() => setShowQuiz(false)}
          src={close}
          alt="Close"
        />
        <div id="qc_embed_signup">
          {renderQuestion()}
        </div>
        <div className="navigation-buttons" style={{ flexDirection: currentQuestionIndex === 0 ? 'row-reverse' : 'row'}}>
          {currentQuestionIndex > 0 && (
            <button className="navigation-button" onClick={handlePrevQuestion}>
              ←
            </button>
          )}
          {currentQuestionIndex < questions.length - 1 && (
            <button className="navigation-button" onClick={handleNextQuestion}>
              →
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizForm;