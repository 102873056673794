import React from 'react'

import { Features, Header, Users } from '../../containers'
import { Navbar, Partners, Mission, Data, Impact, Footer } from '../../components'

import './Home.css'

const Home = ({ context = null }) => {
    return (
        <div className='Home'>
            <Navbar />
            <Header context={context} />
            <Partners />
            <Mission />
            <Features />
            <Data />
            <Users />
            <Impact />
            <Footer hasBorderTop={false} />
        </div>
    )
}

export default Home
