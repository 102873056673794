import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { PortableText } from '@portabletext/react'
import { getImageDimensions } from '@sanity/asset-utils'
import urlBuilder from '@sanity/image-url'

import client from '../../client'

import hibi_banner from '../../assets/hibi_banner.webp'

import styles from './PostBody.css'
import ReactPlayer from 'react-player'

const PostBody = ({ content }) => {
    const windowWidth = useRef(window.innerWidth)

    const [dividedArray, setDividedArray] = useState([])

    useEffect(() => {
        if (content) {
            const dividedArray = divideArray(content)
            setDividedArray(dividedArray)
        }
    }, [content])

    // Function to divide array into 2 distinct arrays
    const divideArray = (arr) => {
        return arr.map((item) => [item])
    }

    // Barebones lazy-loaded image component
    const SampleImageComponent = ({ value, isInline }) => {
        const { width, height } = getImageDimensions(value)
        return (
            <div className='flex justify-center'>
                <img
                    src={urlBuilder(client)
                        .image(value)
                        .width(isInline ? 100 : Math.floor(windowWidth.current * 0.8))
                        .fit('max')
                        .auto('format')
                        .url()}
                    alt={value.alt || ' '}
                    loading='lazy'
                    style={{
                        display: isInline ? 'inline-block' : 'block',
                        objectFit: 'contain',
                        overflow: 'hidden',
                        aspectRatio: width / height,
                    }}
                />
            </div>
        )
    }

    const PostBodyChunk = ({ contentChunk }) => {
        let contentChunkModified = contentChunk

        try {
            if (!contentChunk[0].hasOwnProperty('children')) {
                for (const [index, x] of contentChunkModified.entries()) {
                    if (x._type === 'span' && x.text === '<BANNER>') {
                        contentChunkModified[index] = { _type: 'banner' }
                    }
                }
            } else {
                // Get children from the content chunk (each content chunk is of size 1 as per divideArray())
                const children = contentChunk[0].children

                for (const [index, x] of children.entries()) {
                    if (x._type === 'span' && x.text === '<BANNER>') {
                        contentChunkModified[0].children[index] = { _type: 'banner' }
                    }
                }
            }
        } catch (error) {
            console.log('Got error trying to insert banner:', error)
        }

        return (
            <PortableText
                value={contentChunkModified}
                components={{
                    block: {
                        // Customize block types with ease
                        normal: ({ children }) => (
                            <p className='text-[18px] font-Inter leading-normal font-light'>
                                {children}
                            </p>
                        ),
                        h4: ({ children }) => (
                            <h4 className='text-xl text-[#1E4D32] font-Inter'>{children}</h4>
                        ),
                        h3: ({ children }) => (
                            <h3 className='text-2xl text-[#1E4D32]  font-Inter'>{children}</h3>
                        ),
                        h2: ({ children }) => (
                            <h2 className='text-3xl text-[#1E4D32] font-Inter'>{children}</h2>
                        ),
                        h1: ({ children }) => (
                            <h1 className='text-4xl text-[#1E4D32] font-Inter'>{children}</h1>
                        ),
                    },
                    list: ({ children, attributes }) => (
                        <ul className='list-disc pl-5 mt-5'>{children}</ul>
                    ),
                    listItem: ({ children, attributes }) => (
                        <li className='text-[18px] font-Inter leading-normal font-light'>
                            {children}
                        </li>
                    ),
                    marks: {
                        link: ({ children, value }) => {
                            const rel = !value.href?.startsWith('/')
                                ? 'noreferrer noopener'
                                : undefined

                            return value.href ? (
                                <a
                                    href={value.href}
                                    rel={rel}
                                    className='underline text-blue-500'
                                    target='_blank'
                                >
                                    {children}
                                </a>
                            ) : (
                                <></>
                            )
                        },
                    },
                    types: {
                        image: SampleImageComponent,
                        callToAction: ({ value, isInline }) =>
                            isInline ? (
                                <a href={value.url}>{value.text}</a>
                            ) : (
                                <div className='callToAction'>{value.text}</div>
                            ),
                        youtube: ({ value }) => {
                            const { url } = value
                            return (
                                <div style={{ borderRadius: 16, overflow: 'hidden' }}>
                                    <ReactPlayer width='100%' url={url} />
                                </div>
                            )
                        },
                        banner: () => (
                            <Link className='cta-button' to='https://onelink.to/gn5zbu'>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '2.25rem',
                                        marginLeft: '-1rem',
                                        marginRight: '-1rem',
                                        marginBottom: '1rem',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img
                                        loading='lazy'
                                        src={hibi_banner}
                                        style={{ minWidth: 300, width: '100%', borderRadius: 16 }}
                                        alt='Call to Action'
                                    />
                                </div>
                            </Link>
                        ),
                    },
                }}
            />
        )
    }

    return (
        <div
            className={`mx-auto max-w-2xl mb-10 text-[#757575] tracking-[-0.18px] ${styles.portableText}`}
        >
            {dividedArray &&
                dividedArray.map((contentChunk, index) => (
                    <PostBodyChunk key={index} contentChunk={contentChunk} />
                ))}
        </div>
    )
}

export default PostBody
