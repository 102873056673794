import React from 'react'
import './about.css'

import { Navbar, Backed, Story, Footer } from '../../components'
import { Team, Advisors } from '../../containers'

const About = () => {
    return (
        <div className='Home'>
            <Navbar />
            <Story />
            <Backed />
            <Team />
            <Advisors />
            <Footer />
        </div>
    )
}

export default About
