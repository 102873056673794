import * as React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import {
    Home,
    About,
    Partnerships,
    Contact,
    TermsAndConditions,
    PrivacyPolicy,
    NotFound,
    Blog,
    SingleBlogPost,
    AppDownload,
    RedirectToApp,
    RedirectToDownload,
    MediaKit,
    HibiPlus
} from './pages'

import { customQuizFormEnabled } from './utils'

export default function App() {
    // Hook to scroll the page to the top everytime navigation is changed
    const location = useLocation()
    React.useEffect(() => window.scrollTo({ top: 0, behavior: 'instant' }), [location])

    const ExternalRedirect = ({ to }) => {
        React.useEffect(() => {
          window.location.href = to;
        }, [to]);
      
        return null;
      };

    return (
        <div className='App'>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/app' element={<Home />} />
                <Route path='/parent' element={<Home context='parent' />} />
                <Route path='/loved-one' element={<Home context='loved-one' />} />
                <Route path='/signup-to-newsletter' element={<Home context='signup-to-newsletter' />} />
                <Route path='about' element={<About />} />
                <Route path='partnerships' element={<Partnerships />} />
                <Route path='contact' element={<Contact />} />
                <Route path='contact-us' element={<Contact />} />
                <Route path='app-support' element={<Contact />} />
                <Route path='terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='privacy-policy' element={<PrivacyPolicy />} />
                <Route path='discover' element={<Blog />} />
                <Route path='/discover/:slug' element={<SingleBlogPost />} />
                <Route path='app-download' element={<AppDownload />} />
                <Route path='app-download/*' element={<RedirectToDownload />} />
                <Route path='/invite/*' element={<RedirectToApp />} />
                <Route path='media-kit' element={<MediaKit />} />
                <Route path='plus' element={<HibiPlus />} />
                <Route path='plus-quiz' element={customQuizFormEnabled ? <HibiPlus showQuizInit={true} /> : <ExternalRedirect to="https://form.feathery.io/to/FzIN6h" />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </div>
    )
}
