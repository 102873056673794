import React from 'react'
import './not_found.css'

import { Navbar, Footer } from '../../components'

const NotFound = ({ text = `Sorry, we couldn't find the page you're looking for.`, children }) => {
    return (
        <div className='NotFound'>
            <Navbar />
            <div className='not_found'>
                <h1 className='not_found-title'>
                    404
                    <br />
                    Ooops, page not found
                </h1>
                <p className='not_found-description'>
                    {text}
                </p>
                {children ?? null}
            </div>
            <Footer />
        </div>
    )
}

export default NotFound
