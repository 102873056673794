import React from 'react'
import './data.css'

import ico from '../../assets/ico.webp'
import cyberessentials from '../../assets/cyberessentials.webp'
import cyberessentialsplus from '../../assets/cyberessentialsplus.webp'
import aws from '../../assets/aws.webp'
import arrow_right from '../../assets/arrow_right.webp'

const Link = ({ name, url }) => {
    return (
        <a className='data-link-a' href={url}>
            <p className='data-link'>{name}</p>
            <img loading='lazy' src={arrow_right} alt='Arrow Right' width={24} height={24} />
        </a>
    )
}

const Data = ({ hasBorderTop = true, hasBorderBottom = true, smallerPaddingBottom = false }) => {
    let className = 'data'
    if (hasBorderTop) {
        className += ' data-border-top'
    }
    if (hasBorderBottom) {
        className += ' data-border-bottom'
    }
    return (
        <div className={className} style={{ paddingBottom: smallerPaddingBottom ? '3rem' : undefined }}>
            <div className='data-column'>
                <h1 className='data-title'>
                    Your data, <span style={{ fontFamily: 'GTSectraItalic' }}>safe</span> and{' '}
                    <span style={{ fontFamily: 'GTSectraItalic' }}>secure</span>
                </h1>
            </div>
            <div className='data-column'>
                <div className='data-column-images'>
                    <img 
                        loading='lazy' 
                        src={ico} 
                        alt='ICO'
                        width={60} 
                        height={60} 
                    />
                    <img
                        loading='lazy'
                        src={cyberessentials}
                        alt='Cyber Essentials'
                        width={50}
                        height={60}
                    />
                    <img
                        loading='lazy'
                        src={cyberessentialsplus}
                        alt='Cyber Essentials Plus'
                        width={50}
                        height={60}
                    />
                    <img 
                        loading='lazy' 
                        src={aws} 
                        style={{ borderRadius: 5 }}
                        alt='AWS' 
                        width={60} 
                        height={60} 
                    />
                </div>
                <p className='data-text'>
                    At Hibi, your privacy is our priority. We use industry best standards, alongside
                    technical and organisational measures to safeguard data and keep it secure.
                </p>
                <div className='data-row'>
                    <Link
                        name='Terms and Conditions'
                        url='https://hibi.health/terms-and-conditions'
                    />
                    <Link name='Privacy Policy' url='https://hibi.health/privacy-policy' />
                </div>
            </div>
        </div>
    )
}

export default Data
