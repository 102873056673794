import React, { useState, useEffect } from 'react'

import './app_download.css'

import { Navbar, Footer } from '../../components'

import googlePlayStoreBadge from '../../assets/google_play_badge.webp'
import appleAppStoreBadge from '../../assets/apple_app_store.webp'
import oneLink from '../../assets/onelinkto_gn5zbu.png'

const AppDownload = () => {
    // Set country code (used with Apple app store link)
    const [countryCode, setCountryCode] = useState('gb')
    useEffect(() => {
        const getCountryFromIPAsync = async () => {
            await fetch('https://ipapi.co/json/')
                .then((result) => result.json())
                .then((response) => {
                    if (response.country_name === 'United Kingdom') {
                        setCountryCode('gb')
                    } else if (response.country_name === 'United States') {
                        setCountryCode('us')
                    } else {
                        setCountryCode('gb')
                    }
                })
        }

        getCountryFromIPAsync()
    }, [])

    // Get UTM / attribution metadata
    const source = document.referrer ? new URL(document.referrer).hostname : 'direct'
    const urlParams = new URLSearchParams(window.location.search)
    const medium = urlParams.get('utm_medium')
    const campaign = urlParams.get('utm_campaign')

    const googlePlayStoreURL = `https://play.google.com/store/apps/details?id=com.hibi.app&utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`
    const appleAppStoreURL = `https://apps.apple.com/${countryCode}/app/hibi-health/id6448462377?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`

    return (
        <div className='app-download'>
            <Navbar />
            <div className='app-download-div'>
                <div className='try-call-div'>
                    <p>Scan the QR code to install Hibi</p>
                    <img
                        loading='lazy'
                        src={oneLink}
                        alt='QR Code to Download App' 
                        className='qr-code'
                    />
                </div>
                <div className='app-store-info-app-download'>
                    <div className='app-store-badges-app-download'>
                        <a href={appleAppStoreURL} target='_blank' rel='noopener noreferrer'>
                            <img
                                loading='lazy'
                                src={appleAppStoreBadge}
                                alt='Apple App Store'
                                className='apple-badge'
                            />
                        </a>
                        <a href={googlePlayStoreURL} target='_blank' rel='noopener noreferrer'>
                            <img
                                loading='lazy'
                                src={googlePlayStoreBadge}
                                alt='Google Play Store'
                                className='google-badge'
                            />
                        </a>
                    </div>
                    <p className='secondary-text-app-download'>Try Hibi for free.</p>
                </div>
             </div>
            <Footer />
        </div>
    )
}

export default AppDownload