import React, { useEffect, useState } from 'react'
import close from '../../assets/close.png'
import client from '../../client'
import BlogCard from './BlogCard'
import { formatDA } from '../../utils'

const Searchbar = ({ setsearchMode }) => {
    const [query, setQuery] = useState('')

    const [searchResults, setSearchResults] = useState([])

    const [searchTimeout, setSearchTimeout] = useState(null)
    const [allPosts, setallPosts] = useState([])

    useEffect(() => {
        client
            .fetch(
                `*[_type == "post"] {
                    title,
                    slug,
                    categories[]->{
                        title
                      },
                    publishedAt,
                    mainImage {
                        asset -> {
                            _id,
                            url
                        },
                        alt,
                    },
                    "name": author->name,
                } | order(publishedAt desc)`,
            )
            .then((data) => setallPosts(data))
            .catch(console.error)
    }, [])

    function filterPostsByQuery(allPosts, query) {
        if (!query) {
            return allPosts
        }

        query = query.toLowerCase()

        return allPosts.filter((post) => {
            const title = post.title.toLowerCase()
            return title.includes(query)
        })
    }

    const handleSearch = async () => {
        const results = filterPostsByQuery(allPosts, query)
        setSearchResults(results)
    }

    const handleChange = (e) => {
        setQuery(e.target.value)

        if (searchTimeout) {
            clearTimeout(searchTimeout)
        }

        setSearchTimeout(
            setTimeout(() => {
                handleSearch()
            }, 300),
        )
    }

    return (
        <>
            <div className='relative my-6 md:my-4 lg:my-10'>
                <div className='absolute inset-y-0 left-0 flex items-center pl-3 '>
                    <svg
                        className='w-4 h-4 text-gray-500 dark:text-gray-400'
                        aria-hidden='true'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 20 20'
                    >
                        <path
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                        />
                    </svg>
                </div>
                <input
                    type='text'
                    className=' w-full p-4 pl-10 text-[13px] text-black border border-[#F3F3F3] border-solid rounded-lg font-Inter  focus:border-[#F3F3F3] focus:outline-none '
                    placeholder='Search posts...'
                    onChange={handleChange}
                />
                <img
                    src={close}
                    className='text-white absolute h-12 right-0 bottom-0.5 font-medium rounded-lg text-sm px-4 py-2 opacity-50 '
                    style={{ cursor: 'pointer' }}
                    onClick={() => setsearchMode(false)}
                    alt='Close'
                />
            </div>

            {searchResults.length === 0 && query && query?.length > 0 && (
                <p className='font-GTSectra text-4xl xl:text-5xl flex items-center mb-10 mt-10'>
                    No results found...
                </p>
            )}
            {searchResults.length > 0 && (
                <div className='max-w-screen-xl mx-auto my-8 '>
                    <p className='font-GTSectra text-4xl xl:text-5xl flex items-center mb-10 mt-10'>
                        Results
                    </p>
                    <div className='grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2 gap-10'>
                        {searchResults.map((post, index) => (
                            <BlogCard
                                key={index}
                                title={post?.title}
                                image={post?.mainImage?.asset?.url}
                                date={formatDA(post?.publishedAt)}
                                slug={post?.slug?.current}
                                category={post?.categories[0]?.title}
                                authorName={post?.name}
                                extraStyles='sm:w-1/2'
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}

export default Searchbar
