import React from 'react'
import ef_logo from '../../assets/ef_logo.webp'
import cambridge_logo from '../../assets/cambridge_logo.webp'
import calmstorm_logo from '../../assets/calmstorm_logo.webp'
import './backed.css'

const Backed = () => {
    return (
        <div className='backed'>
            <div className='backed-box'>
                <p className='backed-text'>Backed by</p>
                <div className='backed-row'>
                    <img
                        loading='lazy'
                        src={ef_logo}
                        alt='Entrepreneur First'
                        height={45}
                    />
                    <img
                        loading='lazy'
                        src={cambridge_logo}
                        style={{ marginLeft: 12 }}
                        alt='Cambridge University'
                        height={50}
                    />
                    <img
                        loading='lazy'
                        src={calmstorm_logo}
                        alt='Calm/Storm Ventures'
                        height={30}
                    />
                </div>
            </div>
        </div>
    )
}

export default Backed
