import React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import client from '../../client'

import NotFound from '../not_found/NotFound'
import { CTA } from '../../components'
import BlogContainer from '../../components/blog/BlogContainer'
import PostHeader from '../../components/blog/PostHeader'
import PostBody from '../../components/blog/PostBody'
import { Footer, Navbar } from '../../components'

const SingleBlogPost = () => {
    const [isNotFound, setIsNotFound] = useState(false)
    const [singlePost, setSinglePost] = useState([])
    const { slug } = useParams()

    useEffect(() => {
        client
            .fetch(
                `*[slug.current == "${slug}"] {
          title,
          slug,
          body,
          publishedAt,
          mainImage {
            asset -> {
              _id,
              url
            },
            alt,
          },
          "name": author->name,
      }`,
            )
            .then((data) => {
                if (data.length > 0) {
                    setSinglePost(data[0])
                } else {
                    setIsNotFound(true)
                }
            })
            .catch((error) => console.error(error))
    }, [slug])

    if (isNotFound) {
        return (
            <NotFound text={`Sorry, we couldn't find the article you're looking for.`}>
                <CTA buttonText='Go back to discover' to='https://hibi.health/discover' ctaButtonWidth={248} />
            </NotFound>
        )
    }

    let description = `Check out this article ${singlePost?.name ? `by ${singlePost?.name.trim()} ` : ''}live at Hibi.`
    try {  
        description = singlePost?.body[0]?.children[0]?.text?.trim()
    } catch (error) {}

    return (
        <>
            <Helmet prioritizeSeoTags={true}>
                <meta name="description" content={description} />
                <meta property="og:title" content={singlePost?.title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={`${singlePost?.mainImage?.asset?.url}?w=1200&h=630&fit=crop`} />
                <meta property="og:url" content={`https://hibi.health/discover/${slug}`} />
                <meta property="og:type" content="article" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={singlePost?.title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={`${singlePost?.mainImage?.asset?.url}?w=1200&h=630&fit=crop`} />
            </Helmet>
            <Navbar />
            <BlogContainer>
                {!slug && !singlePost.length > 0 ? (
                    <h1 className='mb-4 mt-8 md:mt-10 lg:mt-12 font-GTSectra text-[#1E4D32] text-[44px] font-bold leading-tight text-left xl:text-[64px] md:leading-none'>
                        Loading..
                    </h1>
                ) : (
                    <article>
                        <PostHeader
                            title={singlePost?.title}
                            coverImage={singlePost?.mainImage?.asset?.url}
                            date={singlePost?.publishedAt}
                            author={singlePost?.name}
                        />
                        <PostBody content={singlePost?.body} />
                    </article>
                )}
            </BlogContainer>
            <Footer />
        </>
    )
}

export default SingleBlogPost
