import React from 'react'
import { Link } from 'react-router-dom'

import './learn_more.css'
import arrow_left from '../../assets/arrow_left.webp'
import checkmark from '../../assets/checkmark.webp'
import lock from '../../assets/lock.webp'
import hibi_plus from '../../assets/hibi_plus.webp'
import information from '../../assets/information.webp'

const LearnMore = ({ setShowLearnMore }) => {
    const features = [
        {
            id: 1,
            name: 'Invite others to your care circle',
            basic: false,
            premium: true,
            slug: 'invite-your-care-circle-to-hibi',
        },
        {
            id: 2,
            name: 'Entries per day',
            basic: 5,
            premium: '∞',
            slug: 'using-hibi-s-journal',
        },
        {
            id: 3,
            name: 'Custom trackers',
            basic: 1,
            premium: '∞',
            slug: 'custom-trackers',
        },
        {
            id: 4,
            name: 'See trends and insights',
            basic: false,
            premium: true,
            slug: 'trends',
        },
        {
            id: 5,
            name: 'Export your journal as a PDF',
            basic: false,
            premium: true,
            slug: 'export-your-journal',
        },
    ]

    return (
        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', justifyItems: 'center', alignSelf: 'center' }}>
            <div id="lm_embed_shell">
                <div style={{ display: 'flex', alignItems: 'center', gap: 4, position: 'absolute', top: 8, left: 16, width: 36, height: 36, cursor: 'pointer', zIndex: 1000 }} onClick={() => setShowLearnMore(false)}>
                    <img style={{ width: 28, height: 28 }} src={arrow_left} alt='Back' />
                    <p style={{ fontFamily: 'GTSectra', color: '#1E4C32', fontSize: 18, marginTop: -2 }}>Back</p>
                </div>
                <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
                <div id="lm_embed_signup">
                    <div className="lm-features-table">
                        <div className="lm-features-row lm-header-row">
                            <div className="lm-features-cell lm-feature-header"></div>
                            <div className="lm-features-cell lm-header" style={{ marginLeft: 5 }}>Basic</div>
                            <div className="lm-features-cell lm-header">
                                <img src={hibi_plus} alt="Hibi Plus" className="lm-hibi-plus-logo" />
                            </div>
                        </div>
                        {features.map((feature, index) => (
                            <div key={index} className={`lm-features-row ${index === features.length - 1 ? 'lm-last-row' : ''}`}>
                                <div className="lm-features-cell lm-feature-cell">
                                    <Link to={`/discover/${feature.slug}`} className="lm-info-button" onClick={() => setShowLearnMore(false)}>
                                        <img className='lm-info-icon' src={information} alt='Information' />
                                    </Link>
                                    <span>{feature.name}</span>
                                </div>
                                <div className="lm-features-cell">
                                    {feature.basic === false ? (
                                        <img src={lock} alt="Lock" className="lm-lock-icon" />
                                    ) : (
                                        <span className="lm-feature-value">{feature.basic}</span>
                                    )}
                                </div>
                                <div className="lm-features-cell">
                                    {feature.premium === true ? (
                                        <img src={checkmark} alt="Checkmark" className="lm-checkmark-icon" />
                                    ) : (
                                        <span className="lm-feature-value">{feature.premium}</span>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LearnMore