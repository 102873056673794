import React from 'react'

const CategoryButton = ({ text, activeCategory, setactiveCategory }) => {
    const isActive = activeCategory === text

    const handleClick = () => {
        setactiveCategory(text)
    }

    return (
        <button
            className={`mt-2 rounded-3xl w-auto py-2 px-4 mb-3 text-[18px] font-Inter leading-normal font-light  border border-solid border-[#1e463233] hover:scale-105 ${
                isActive ? 'bg-[#1E4D32] text-white shadow-sm' : 'bg-white text-[#1E4D32]'
            }`}
            onClick={handleClick}
            style={{ whiteSpace: 'nowrap', display: 'inline-block' }}
        >
            {text}
        </button>
    )
}

export default CategoryButton
