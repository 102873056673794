import React from 'react'
import './feature.css'

const Feature = ({ img, title, subtitle, hasImgMask = true }) => {
    const className = hasImgMask
        ? 'feature feature__margin feature__mask'
        : 'feature feature__margin'

    return (
        <div className={className}>
            <img loading='lazy' className='feature-image' src={img} alt={title} width='90%' />
            <div className='feature-text'>
                <p className='feature-title'>{title}</p>
                <p className='feature-subtitle'>{subtitle}</p>
            </div>
        </div>
    )
}

export default Feature
