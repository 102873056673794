import React from 'react'
import { Member } from '../../components'
import laurence from '../../assets/laurence.webp'
import anel from '../../assets/anel.webp'
import jess from '../../assets/jess.webp'
import richard from '../../assets/richard.webp'
import './advisors.css'

const Advisors = () => {
    return (
        <div className='advisors'>
            <h1 className='advisors-title'>Our Advisors</h1>
            <div className='advisors-row'>
                <Member
                    imgSource={laurence}
                    name='Laurence Bargery'
                    role='Advisor'
                    bio='Laurence is a healthtech entrepreneur, software engineer and start-up advisor. He started Accurx 7 years ago and was CTO for the first 6.5 years before leading their infrastructure and platform team. In addition to his technical skills, he has a real passion for how to build company culture and diversity & inclusion.'
                />
                <Member
                    imgSource={anel}
                    name='Anel van der Merwe'
                    role='Advisor'
                    bio='Anel is the founder of Children in Motion, and a paediatric physiotherapist. Anel has extensive experience across health and care systems, and a special interest in working with children with developmental delay and whose difficulties are complex and neurological in nature.'
                />
            </div>
            <div className='advisors-row'>
                <Member
                    imgSource={jess}
                    name='Jess Meredith'
                    role='Advisor'
                    bio='As a proud ADHDer and parent to neurodiverse children, Jess founded and runs Differing Minds - a social enterprise dedicated to neurodiversity advocacy. She also has a rich background in technology spanning start-ups, scale-ups and global corporations, with a particular interest in data and accessibility.'
                />
                <Member
                    imgSource={richard}
                    name='Richard Daniels'
                    role='Advisor'
                    bio='Richard is a paediatrician with extensive experience in many areas of Child Health. He has been working within Digital Health for 3 years and has had a leading role in several digital health services ranging from telehealth startups to national digital health projects for the NHS.'
                />
            </div>
        </div>
    )
}

export default Advisors
