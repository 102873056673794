import React from 'react'
import './media_kit.css'

import { Navbar, Footer } from '../../components'

import hibi_logo from '../../assets/hibi_logo.webp'
import app_screenshot from '../../assets/app_screenshot.webp'
import sample_post from '../../assets/sample_post.webp'

const MediaKit = () => {
    const Bundle = ({ title, text, imgSource, link }) => {
        return (
            <div className='bundle'>
                <a href={link} target='_blank' rel='noopener noreferrer'>
                    <img src={imgSource} alt={title} className='bundle-image' />
                    <h3 className='bundle-title'>{title}</h3>
                    <p className='bundle-text'>{text}</p>
                    <p className='bundle-cta'>View all →</p>
                </a>
            </div>
        );
    };

    return (
        <div>
            <Navbar />
            <div className='media-kit'>
                <h1 className='media-kit-title'>Media Kit</h1>
                <div className='media-kit-content'>
                    <div className='bundle-column'>
                        <Bundle
                            title='Branding'
                            text="The Hibi marks include our name, logo, and any branding that identifies the source of our products."
                            imgSource={hibi_logo}
                            link='https://drive.google.com/drive/folders/1ReFNTYoehz3gA6HgE9kCHWtJ9HKOhuJP?usp=sharing'
                        />
                    </div>
                    <div className='bundle-column'>
                        <Bundle
                            title='Product'
                            text="Worth a thousand words: stunning high-res screenshots of Hibi shining on every device!"
                            imgSource={app_screenshot}
                            link='https://drive.google.com/drive/folders/1g88ZsXqBBARvw697HSoIbN4aSeDHnrjj?usp=sharing'
                        />
                    </div>
                    <div className='bundle-column'>
                        <Bundle
                            title='Socials'
                            text="Share your love for Hibi! We've got the eye-catching flyers and social media assets you need to make a splash."
                            imgSource={sample_post}
                            link='https://drive.google.com/drive/folders/1JDlhtARYbCv2Thx0U2KLEsCI55ZNCUyN?usp=sharing'
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MediaKit;