import React from 'react'
import './member.css'

const Member = ({ imgSource, name, role, bio, link = null }) => {
    return (
        <div className='member'>
            <img loading='lazy' src={imgSource} alt={name} width={320} height={320} />
            <div className='member-text'>
                <p className='member-name'>{name}</p>
                <p className='member-role'>{role}</p>
                {link ? <p className='member-bio'><a href={link}>{name.split(' ')[0]}</a> {bio}</p> : <p className='member-bio'>{bio}</p>}
            </div>
        </div>
    )
}

export default Member
