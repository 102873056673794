import React from 'react'

import './mailchimp_form.css'
import close from '../../assets/close.png'

const MailchimpForm = ({ setShowForm }) => {
    return (
        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', justifyItems: 'center', alignSelf: 'center' }}>
            <div id="mc_embed_shell">
                <img style={{ position: 'absolute', top: 8, right: 8, width: 36, height: 36, cursor: 'pointer' }} onClick={() => setShowForm(false)} src={close} alt='Close' />
                <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
                <div id="mc_embed_signup">
                    <form action="https://health.us21.list-manage.com/subscribe/post?u=9360bc09194eaf010541f1895&amp;id=21b45270e0&amp;f_id=0013ffe6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                        <div id="mc_embed_signup_scroll">
                            <h1>Subscribe to our newsletter</h1>
                            <p style={{ fontFamily: 'Inter', color: '#757575', fontSize: 16, marginBottom: 8, marginTop: -4, marginLeft: 4 }}>If you subscribe, we'll keep you updated on news and product updates via email <span style={{ marginLeft: 1.5 }}>💚</span></p>
                            <div className="mc-field-group">
                                <input style={{ fontFamily: 'Inter', color: '#222222', fontSize: 16, marginTop: 8, borderRadius: 4, marginLeft: 2.5, paddingLeft: 2, outlineColor: '#1E4C32' }} type="email" name="EMAIL" className="required email" id="mce-EMAIL" required="" placeholder='Your email' />
                            </div>
                            <div id="mce-responses" className="clear">
                                <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                                <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                            </div>
                            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                                <input type="text" name="b_9360bc09194eaf010541f1895_21b45270e0" tabIndex="-1" />
                            </div>
                            <div className="clear" style={{ marginTop: 4, marginBottom: -12 }}>
                                <input style={{ backgroundColor: '#1E4C32', fontFamily: 'Inter', fontSize: 16, borderRadius: 4, marginLeft: 2.5 }} type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default MailchimpForm