import React from 'react'

const BlogCard = ({ category, title, image, slug, authorName }) => {
    return (
        <a href={`/discover/${slug}`}>
            <div className='rounded-lg border-[#F3F3F3] flex flex-col justify-end border-solid border shadow-sm h-[20rem] hover:border-[#1E4C32] '>
                <div
                    className='relative overflow-hidden bg-cover bg-no-repeat rounded-t-lg  hover:scale-110'
                    data-te-ripple-init
                    data-te-ripple-color='light'
                >
                    <img
                        className='object-cover w-full h-48 hover:scale-110 flex'
                        src={image}
                        alt={`Cover for ${title}`}
                    />
                </div>
                <div className='pt-2 px-2 mb-4'>
                    <h5 className='text-[22px] pl-3 xl:text-[22px] font-light leading-tight text-black '>
                        {title}
                    </h5>
                    <h3 className='text-[12px] pl-3 font-Inter leading-normal font-light text-[#757575] mt-2 '>
                        by {authorName}
                    </h3>
                    <div className='inline-block text-[12px] font-Inter leading-normal font-light border border-solid border-[#1e463233] rounded-3xl px-6 py-2 mt-5 text-[#1E4C32]'>
                        {category}
                    </div>
                </div>
            </div>
        </a>
    )
}

export default BlogCard
