import React from 'react'
import './privacy_policy.css'

import { Navbar, Footer } from '../../components'

const PrivacyPolicy = () => {
    return (
        <div className='PrivacyPolicy'>
            <Navbar />
            <div className='privacy_policy'>
                <h1 className='privacy_policy-title'>Privacy Policy</h1>
                <p className='privacy_policy-description'>
                    <p className='privacy_policy-highlight'>
                        <p>Protecting your personal information is our top priority, so we want to be upfront about how we collect, use, and safeguard your data. Here's a quick rundown of our Privacy Policy:</p>
                        <p><strong>Data We Collect.</strong> When you create your account, we gather basic info like your name and email. As you use the app, you choose what to share about your child's health journey.</p>
                        <p><strong>Keeping Your Data Secure.</strong> We take multiple measures to secure your data, like encrypting it and storing it on secure servers. We're also ICO and Cyber Essentials certified, so you can trust that we're following best practices. You're in control of your data and can request to access, correct, or delete it anytime.</p>
                        <p><strong>How We Use Your Info.</strong> We use your data to provide and enhance the Hibi service and may send you updates about the app. We'll never sell your personal information.</p>
                        <p><strong>Data Retention and Deletion.</strong> We'll hold your data only as long as needed or until you request deletion. If you close your account, we may retain data for up to 5 years for legal purposes.</p>
                        <p><strong>Third-Party Links.</strong> Hibi may include links to external websites. Remember, this policy only covers Hibi, so review the privacy terms of any other sites you visit. </p>
                        <p><strong>Your Rights.</strong> You have the right to object to our use of your data, request copies, have it corrected or deleted, and more. Reach out to us at <a href='mailto:hello@hibi.health'>hello@hibi.health</a> to exercise your rights.</p>
                        <p><strong>Policy Updates.</strong> As Hibi evolves, we may update this policy. We'll post changes on our website and app, and your continued use signifies acceptance of the new terms.</p>
                        <p>There you have it - the key points of our Privacy Policy. We encourage you to read the full legal document below for all the details. If you have any questions, don't hesitate to get in touch!</p>
                    </p>
                    &nbsp;
                    &nbsp;
                    <p>
                        <strong>
                            Please read this Privacy Policy carefully (as of 28/03/2024)
                        </strong>
                        .
                    </p>

                    <p>
                        This privacy policy applies between you, the User of the Service provided
                        via the Hibi App, and&nbsp;Hibi Health Ltd, the owner and provider of the
                        Service.&nbsp;Hibi Health Ltd&nbsp;takes the privacy of your information
                        very seriously. This privacy policy applies to our use of any and all Data
                        collected by us or provided by you in relation to your use of the Service.
                    </p>

                    <p>
                        This Privacy Policy should be read alongside, and in addition to, our Terms
                        and Conditions, which can be found at:&nbsp;
                        <a href='https://hibi.health/terms-and-conditions/'>
                            https://hibi.health/terms-and-conditions/
                        </a>
                        .{' '}
                    </p>

                    <h3>
                        <strong>1.</strong> <strong>Definitions and Interpretation</strong>
                    </h3>

                    <p>In this privacy policy, the following definitions are used:</p>

                    <p>
                        <strong>Data</strong>: collectively all information that you submit
                        to&nbsp;Hibi Health Ltd&nbsp;via the App. This definition incorporates,
                        where applicable, the definitions provided in the Data Protection Laws.
                    </p>

                    <p>
                        <strong>Data Protection Laws</strong>: any applicable law relating to the
                        processing of personal Data, including but not limited to the&nbsp;GDPR, and
                        any national implementing and supplementary laws, regulations and secondary
                        legislation.
                    </p>

                    <p>
                        <strong>GDPR</strong>: the UK General Data Protection Regulation;
                    </p>

                    <p>
                        <strong>Hibi Health Ltd</strong>, <strong>Hibi</strong>, <strong>we</strong>
                        , or <strong>us</strong>: Hibi Health Ltd, &nbsp;a company registered
                        in&nbsp;England and Wales&nbsp;under number&nbsp;14527962&nbsp;whose
                        registered office is at&nbsp;125-127 Mare Street, London,&nbsp;E8 3RH;&nbsp;(the&nbsp;<strong>Supplier</strong>&nbsp;or&nbsp;
                        <strong>us</strong>&nbsp;or&nbsp;<strong>we</strong>). You can contact us by
                        emailing us at <a href='mailto:hello@hibi.health'>hello@hibi.health</a> or
                        at <a href='http://www.hibi.health'>www.hibi.health</a> (the “Website”).
                    </p>

                    <p>
                        <strong>User </strong>or <strong>you</strong>: any customer that accesses
                        the Service and is not either (i) employed by Hibi Health Ltd and acting in
                        the course of their employment or (ii) engaged as a consultant or otherwise
                        providing services to Hibi Health Ltd and accessing the Service in
                        connection with the provision of such services.
                    </p>

                    <p>
                        <strong>Child</strong>: the child profile created during account creation and used during sustained usage of the App by the User.
                    </p>

                    <p>
                        <strong>Service: </strong>The service provided via the Hibi app (the “App”)
                        available in the Apple App Store and Google Play Store (the “Stores”).
                    </p>

                    <p>
                        In this Privacy Policy, unless the context requires a different
                        interpretation: (i) the singular includes the plural and vice versa; (ii)
                        references to sub-clauses, clauses, schedules or appendices are to
                        sub-clauses, clauses, schedules or appendices of this Privacy Policy; (iii)
                        a reference to a person includes firms, companies, government entities,
                        trusts and partnerships; (iv) "including" is understood to mean "including
                        without limitation"; (v) reference to any statutory provision includes any
                        modification or amendment of it; (vi) the headings and sub-headings do not
                        form part of this Privacy Policy.
                    </p>

                    <p>
                        This privacy policy applies only to the actions of&nbsp;Hibi Health
                        Ltd&nbsp;and Users with respect to the App. It does not extend to any
                        sources or information that can be accessed from the App including, but not
                        limited to, any links to social media and other websites.
                    </p>

                    <h3>
                        <strong>2.</strong> <strong>Data Collected</strong>
                    </h3>

                    <p>
                        The following data is collected from the user during account creation:
                            (i) User’s Consent to Terms and Conditions and Privacy Policy;
                            (ii) User’s first name;
                            (iii) User’s last name (optional);
                            (iv) User’s valid email;
                            (v) User’s phone number (optional);
                            (vi) User’s coarse location (optional);
                            (vii) User’s profile picture (optional);
                            (viii) User’s role in care (optional);
                            (ix) Child’s first name;
                            (x) Child’s last name (optional);
                            (xi) Child’s date of birth;
                            (xii) Child’s profile picture (optional); in each case, in accordance with this Privacy Policy.                    
                    </p>
                    <p>
                        The following data may be collected during sustained usage of the App by the User, entirely optional and solely up to the User if they choose to input and then to share within the app if they choose to do so:
                            (i) Child’s medical conditions;
                            (ii) Child’s NHS number;
                            (iii) Child’s snapshot and overview (e.g., likes, dislikes, behaviours);
                            (iv) Child’s medication;
                            (v) Child’s communication and sensory needs;
                            (vi) Child’s appointments;
                            (vii) Child’s medication logs (e.g., skipped, taken);
                            (viii) Child’s symptoms and behaviours (dated and rated by the User);
                            (ix) Child’s medical and non-medical documents;
                            (x) User’s reminders for medication, notes, and appointment;
                            (xi) User’s non-medical questions under the guidance section; in each case, in accordance with this Privacy Policy.
                    </p>

                    <p>
                        We collect Data in the following ways: (i) data is given to us by you; (ii)
                        data is collected automatically.
                    </p>

                    <p>
                        Hibi Health Ltd&nbsp;will collect your Data in a number of ways, for
                        example: (i) when you contact us through the Website, by telephone, post,
                        email or through any other means; (ii) when you sign up and set up an
                        account in the App; (iii) when you use the Service provided via the App; in
                        each case, in accordance with this Privacy Policy.
                    </p>

                    <p>
                        To the extent that you access the Service, we will collect your Data
                        automatically, for example: (i) we automatically collect some information
                        about your visit to the Website. This information helps us to make improvements to Website and
                        Service content and navigation, and includes your IP address, the date,
                        times and frequency with which you access the Website and the way you use
                        and interact with its content.
                    </p>

                    <p>
                        We may use Data to: (i) enable you access to the Hibi App; (ii) send you
                        information about the app, our company, and the development of the app;
                        (iii) generate anonymous and aggregated insights to inform product development.
                    </p>

                    <h3>
                        <strong>3.</strong> <strong>Our Use of Data</strong>
                    </h3>

                    <p>
                        Any or all of the above Data may be required by us from time to time in
                        order to provide you with the best possible service and experience when
                        using our Service. Specifically, Data may be used by us for the following
                        reasons: (i) internal record keeping; (ii) improvement of our products and
                        services; in each case, in accordance with this Privacy Policy.
                    </p>

                    <p>
                        We may use your Data for the above purposes if we deem it necessary to do so
                        for our legitimate interests. If you are not satisfied with this, you have
                        the right to object in certain circumstances (see the section headed "Your
                        Rights" below).
                    </p>

                    <p>
                        When you register with us and set up an account to receive our services, the
                        legal basis for this processing is the performance of a contract between you
                        and us and taking steps, at your request, to enter into such a contract.
                    </p>

                    <h3>
                        <strong>4.</strong> <strong>Keeping Data Secure</strong>
                    </h3>

                    <p>
                        We will use technical and organisational measures to safeguard your Data,
                        for example: (i) access to your account is controlled by a password and a
                        username that is unique to you; (ii) we store your Data on secure servers.
                    </p>

                    <p>
                        We are ICO and&nbsp;Cyber Essentials certified. This family of standards
                        helps us manage your Data and keep it secure.
                    </p>

                    <p>
                        Technical and organisational measures include measures to deal with any
                        suspected data breach. If you suspect any misuse or loss or unauthorised
                        access to your Data, please let us know immediately by contacting us via
                        this email address:&nbsp;
                        <a href='mailto:hello@hibi.health'>hello@hibi.health</a>.
                    </p>

                    <p>
                        If you want detailed information from Get Safe Online on how to protect your
                        information and your computers and devices against fraud, identity theft,
                        viruses and many other online problems, please visit{' '}
                        <a href='http://www.getsafeonline.org'>www.getsafeonline.org</a>. Get Safe
                        Online is supported by HM Government and leading businesses.
                    </p>

                    <h3>
                        <strong>5.</strong> <strong>Data Retention</strong>
                    </h3>

                    <p>
                        Unless a longer retention period is required or permitted by law, we will
                        only hold your Data on our systems for the period necessary to fulfil the
                        purposes outlined in this Privacy Policy or until you request that the Data
                        be deleted.
                    </p>

                    <p>
                        If you delete your account from the App, we retain Data for up to 5 years
                        after date on which your account was closed.
                    </p>

                    <p>
                        Even if we delete your Data, it may persist on backup or archival media for
                        legal, tax or regulatory purposes.
                    </p>

                    <h3>
                        <strong>6.</strong> <strong>Your Rights</strong>
                    </h3>

                    <p>You have the following rights in relation to your Data:</p>

                    <p>
                        <strong>Right to access</strong>&nbsp;- the right to request (i) copies of
                        the information we hold about you at any time, or (ii) that we modify,
                        update or delete such information. If we provide you with access to the
                        information we hold about you, we will not charge you for this, unless your
                        request is "manifestly unfounded or excessive." Where we are legally
                        permitted to do so, we may refuse your request. If we refuse your request,
                        we will tell you the reasons why.
                    </p>

                    <p>
                        <strong>Right to correct</strong>&nbsp;- the right to have your Data
                        rectified if it is inaccurate or incomplete.
                    </p>

                    <p>
                        <strong>Right to erase</strong>&nbsp;- the right to request that we delete
                        or remove your Data from our systems.
                    </p>

                    <p>
                        <strong>Right to restrict our use of your Data</strong>&nbsp;- the right to
                        "block" us from using your Data or limit the way in which we can use it.
                    </p>

                    <p>
                        <strong>Right to data portability</strong>&nbsp;- the right to request that
                        we move, copy or transfer your Data.
                    </p>

                    <p>
                        <strong>Right to object</strong>&nbsp;- the right to object to our use of
                        your Data including where we use it for our legitimate interests.
                    </p>

                    <p>
                        To make enquiries, exercise any of your rights set out above, or withdraw
                        your consent to the processing of your Data (where consent is our legal
                        basis for processing your Data), please contact us via this email
                        address:&nbsp;<a href='mailto:hello@hibi.health'>hello@hibi.health</a>.
                    </p>

                    <p>
                        If you are not satisfied with the way a complaint you make in relation to
                        your Data is handled by us, you may be able to refer your complaint to the
                        relevant data protection authority. For the UK, this is the Information
                        Commissioner's Office (ICO). The ICO's contact details can be found on their
                        website at <a href='https://ico.org.uk/'>https://ico.org.uk/</a>.
                    </p>

                    <p>
                        It is important that the Data we hold about you is accurate and current.
                        Please keep us informed if your Data changes during the period for which we
                        hold it.
                    </p>

                    <h3>
                        <strong>7.</strong> <strong>Links to Other Website and Sources</strong>
                    </h3>

                    <p>
                        The App may, from time to time, provide links to other websites and sources.
                        We have no control over such websites and sources and are not responsible
                        for the content of they contain. This Privacy Policy does not extend to your
                        use of such websites and sources. You are advised to read the Privacy Policy
                        or Statement of other websites and sources prior to using them.
                    </p>

                    <h3>
                        <strong>8.</strong> <strong>General</strong>
                    </h3>

                    <p>
                        You may not transfer any of your rights under this Privacy Policy to any
                        other person. We may transfer our rights under this Privacy Policy where we
                        reasonably believe your rights will not be affected.
                    </p>

                    <p>
                        If any court or competent authority finds that any provision of this Privacy
                        Policy (or part of any provision) is invalid, illegal or unenforceable, that
                        provision or part-provision will, to the extent required, be deemed to be
                        deleted, and the validity and enforceability of the other provisions of this
                        privacy policy will not be affected.
                    </p>

                    <p>
                        Unless otherwise agreed, no delay, act or omission by a party in exercising
                        any right or remedy will be deemed a waiver of that, or any other, right or
                        remedy.
                    </p>

                    <p>
                        This Agreement will be governed by and interpreted according to the law
                        of&nbsp;England and Wales. All disputes arising under the Agreement will be
                        subject to the exclusive jurisdiction of the&nbsp;English and Welsh courts.
                    </p>

                    <h3>
                        <strong>9.</strong> <strong>Change to this Privacy Policy</strong>
                    </h3>

                    <p>
                        Hibi Health Ltd&nbsp;reserves the right to change this Privacy Policy as we
                        may deem necessary from time to time or as may be required by law. Any
                        changes will be immediately posted on the Website and the App and you are
                        deemed to have accepted the terms of the Privacy Policy on your first use of
                        the Service following the alterations.&nbsp;&nbsp;You may contact&nbsp;Hibi
                        Health Ltd&nbsp;by email at&nbsp;
                        <a href='mailto:hello@hibi.health'>hello@hibi.health</a>.
                    </p>
                </p>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy
