import React, { useEffect, useState } from 'react'
import client from '../../client'
import InfiniteScroll from 'react-infinite-scroll-component'
import { formatDA } from '../../utils'
import BlogCard from './BlogCard'

const BlogsDisplay = ({ activeCategory }) => {
    const [posts, setPosts] = useState([])
    const [lastFetchedIndex, setLastFetchedIndex] = useState(5)
    const [hasMore, setHasMore] = useState(true)
    const isLocalhost = window.location.href.includes('localhost')

    useEffect(() => {
        const fetchInitialPosts = async () => {
            try {
                setLastFetchedIndex(5)
                let query
                if (activeCategory === 'All') {
                    query = `*[_type == "post"] {
                    title,
                    slug,
                    categories[]->{
                        title
                      },
                    publishedAt,
                    mainImage {
                        asset -> {
                            _id,
                            url
                        },
                        alt,
                    },
                    "name": author->name,
                } | order(publishedAt desc) [0..${isLocalhost ? '999999' : '5'}]`
                } else {
                    query = `*[_type == "category" && title == "${activeCategory}"]{
                            _id,
                            title,
                        "post": *[_type == "post" && references(^._id)] {
                    title,
                    slug,
                    categories[]->{
                        title
                      },
                    publishedAt,
                    mainImage {
                        asset -> {
                            _id,
                            url
                        },
                        alt,
                    },
                    "name": author->name,
                } | order(publishedAt desc) [0..5]
                }`
                }

                let initialPosts = await client.fetch(query)
                initialPosts = activeCategory === 'All' ? initialPosts : initialPosts[0]?.post
                setPosts(initialPosts)
                setHasMore(initialPosts.length < 6 ? false : true)
            } catch (error) {
                console.error('Error fetching initial posts: ')
            }
        }
        fetchInitialPosts()
    }, [activeCategory, isLocalhost])

    const fetchMorePosts = async () => {
        try {
            let query
            if (activeCategory === 'All') {
                query = `*[_type == "post"] {
                    title,
                    slug,
                    categories[]->{
                        title
                      },
                    publishedAt,
                    mainImage {
                        asset -> {
                            _id,
                            url
                        },
                        alt,
                    },
                    "name": author->name,
                } | order(publishedAt desc)[${lastFetchedIndex + 1}..${lastFetchedIndex + 6}]`
            } else {
                query = `*[_type == "category" && title == "${activeCategory}"]{
                    _id,
                    title,
                    "post": *[_type == "post" && references(^._id)] {
                        title,
                        slug,
                        categories[]->{
                            title
                        },
                        publishedAt,
                        mainImage {
                        asset -> {
                            _id,
                            url
                        },
                        alt,
                    },
                    "name": author->name,
                    } | order(publishedAt desc) [${lastFetchedIndex + 1}..${lastFetchedIndex + 6}]
                }`
            }
            let morePosts = await client.fetch(query)
            morePosts = activeCategory === 'All' ? morePosts : morePosts[0]?.post

            if (morePosts.length === 0) {
                setHasMore(false)
            } else {
                setPosts((prevPosts) => [...prevPosts, ...morePosts])
                setLastFetchedIndex(lastFetchedIndex + 6)
            }
        } catch (error) {
            console.error('Error fetching more posts: ', error)
        }
    }

    return (
        <div className='max-w-screen-xl mx-auto my-8'>
            <InfiniteScroll dataLength={posts.length} next={fetchMorePosts} hasMore={hasMore}>
                {posts.length > 0 && (
                    <div className='grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2 gap-10'>
                        {posts.map((post, index) => (
                            <BlogCard
                                key={index}
                                category={post.categories[0]?.title}
                                title={post.title}
                                image={post.mainImage?.asset?.url}
                                date={formatDA(post.publishedAt)}
                                extraStyles='sm:w-1/2'
                                slug={post.slug?.current}
                                authorName={post.name}
                            />
                        ))}
                    </div>
                )}
            </InfiniteScroll>
        </div>
    )
}

export default BlogsDisplay
