import React from 'react'
import './users.css'

import instagram from '../../assets/instagram.webp'
import facebook from '../../assets/facebook.webp'
import arrow_up_right from '../../assets/arrow_up_right.webp'

import { Quote } from '../../components'

const Users = () => {
    return (
        <div className='users'>
            <div className='users-row'>
                <div style={{ flex: 1 }}>
                    <h1 className='users-title'>
                        What our <span style={{ fontFamily: 'GTSectraItalic' }}>users</span> say
                    </h1>
                </div>
                <div className='users-links'>
                    <a href='https://www.instagram.com/hibihealth/'>
                        <img
                            loading='lazy'
                            src={instagram}
                            width={24}
                            height={24}
                            alt='Instragram'
                        />
                        Instagram
                        <img
                            loading='lazy'
                            src={arrow_up_right}
                            width={24}
                            height={24}
                            alt='Arrow Up Right'
                        />
                    </a>
                    <a href='https://www.facebook.com/people/Hibi/100089603070874/'>
                        <img loading='lazy' src={facebook} width={24} height={24} alt='Facebook' />
                        Facebook
                        <img
                            loading='lazy'
                            src={arrow_up_right}
                            width={24}
                            height={24}
                            alt='Arrow Up Right'
                        />
                    </a>
                </div>
            </div>
            <div className='users-row users-row-quotes'>
                <Quote quote='Absolutely loving the features... a life saver for families' />
                <Quote quote='Just found this app and it’s AMAZING! For any mama of a medically complex kiddo, check this app out!' />
                <Quote quote="Every special needs parent's dream" />
                <Quote quote="I've been waiting for something like this for years" />
                <Quote quote="It puts all the vital information in one place and allows me to connect my sons team" />
            </div>
            <div className='users-row users-row-quotes'>
                <Quote quote='This is a game changer' />
                <Quote quote='It has made communicating with family members and caregivers such a breeze' />
                <Quote quote='This will change the life of so many' />
                <Quote quote="This app has been amazing to use, combining three different apps we use into one - you can track everything in one place… it has made our life so much easier" />
                <Quote quote="Wait, an app with us in mind?! Could it be? Did somebody just throw us a bone?!?! I feel so seen!" />
            </div>
        </div>
    )
}

export default Users
