
import React from 'react'

import './redirect_to_download.css'

import { Navbar, Footer } from '../../components'

import invite_care_circle from '../../assets/invite_care_circle.webp'

const RedirectToDownload = () => { // Page to redirect to the onelink for App Store or Google Play tore download page
    const url = 'https://onelink.to/gn5zbu'

    React.useEffect(() => {
        window.location.href = url
    }, [url])
  
    return ( 
        <div className='RedirectToDownload'>
            <Navbar /> 
            <div className='redirect_to_download'>
                <img
                    loading='lazy'
                    className='invite-care-circle-image'
                    src={invite_care_circle}
                    alt='Download the Hibi app'
                    width={600}
                />
                <h3>Redirecting you to the Hibi app...</h3> 
                <p>This should only take a few seconds. If this does not work, please make sure that the <a href='https://onelink.to/gn5zbu'>Hibi app</a> is installed on your device and try again.</p> 
            </div>
            <Footer />
        </div>
    )
}

export default RedirectToDownload