import React, { useState, useEffect } from 'react'

const OptimizedImage = ({ 
    loading = 'lazy', 
    src,
    lowSrc, 
    alt
}) => {
    const [imageLoaded, setImageLoaded] = useState(false)

    useEffect(() => {
        const img = new Image() 
        img.onload = () => {
            setImageLoaded(true)
        }
        img.src = src
    }, [src])

    return (
        <>
            <img 
                loading={loading}
                src={lowSrc} 
                alt={alt}
                style={{ display: imageLoaded ? 'none' : 'inline' }}
            />
            <img 
                loading={loading}
                src={src} 
                alt={alt}
                style={{ display: !imageLoaded ? 'none' : 'inline' }}
            />
        </>
    )
}


export default OptimizedImage