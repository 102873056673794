import React from 'react'

import { Member } from '../../components'

import sam from '../../assets/sam.webp'
import uzay from '../../assets/uzay.webp'
import kush from '../../assets/kush.webp'
import mel from '../../assets/mel.webp'

import './team.css'

import { useWindowDimensions, convertRemToPixels } from '../../utils'

const Team = () => {
    const { width } = useWindowDimensions()
    const lineWidth = width - convertRemToPixels(width <= 550 ? 4.5 : width <= 700 ? 6 : 15)

    return (
        <div className='team'>
            <div className='team-line' style={{ width: lineWidth }} />
            <h1 className='team-title'>Meet our team</h1>
            <div className='team-row'>
                <Member
                    imgSource={sam}
                    name='Sam Milliken-Smith'
                    role='Co-Founder'
                    bio='Sam has spent his career working with healthcare organisations, including across the NHS and internationally. He co-founded Hibi having seen personally & professionally the challenges facing families caring for children with additional needs.'
                />
                <Member
                    imgSource={uzay}
                    name='Uzay Macar'
                    role='Co-Founder'
                    link='https://uzaymacar.github.io/'
                    bio='is an entrepreneur, researcher, and software engineer. He has been involved in a multitude of commercial and research projects at the intersection of technology and healthcare across the US, Canada, and UK, founding a startup in this space before.'
                />
            </div>
            <div className='team-row'>
                <Member
                    imgSource={kush}
                    name='Kush Gaikwad'
                    role='Lead Engineer'
                    link='https://portfoliio-dusky.vercel.app/'
                    bio='is a highly skilled software engineer with valuable experience working in successful startups. He is passionate about using  emergent technologies to uplift culture and strengthen communities.'
                />
                <Member
                    imgSource={mel}
                    name='Melanie Dimmitt'
                    role='Content & Community'
                    link='https://www.melaniedimmitt.com.au/'
                    bio="is a freelance journalist. Since launching her debut book, Special: antidotes to the obsessions that come with a child's disability, Melanie has written, spoken, podcasted and advocated far and wide for parents travelling not-so-typical paths."
                />
            </div>
        </div>
    )
}

export default Team
