import React from 'react'

import phab from '../../assets/phab.webp'
import cambridge_logo from '../../assets/cambridge_logo.webp'
import ept from '../../assets/ept.webp'
import keen from '../../assets/keen.webp'
//import samuels_charity from '../../assets/samuels_charity.webp'
//import affinity_hub from '../../assets/affinity_hub.webp'
import mencap from '../../assets/mencap.webp'
import just4children from '../../assets/just4children.webp'
import digital_health_london from '../../assets/digital_health_london.webp'
import mayor_of_london from '../../assets/mayor_of_london.webp'
import differing_minds from '../../assets/differing_minds.webp'
import children_in_motion from '../../assets/children_in_motion.webp'
import ukri from '../../assets/ukri.webp'
import movement_centre from '../../assets/movement_centre.webp'

import './partners.css'

const Logo = ({ src, alt, height = 60 }) => {
    return (
        <img 
            loading='lazy' 
            src={src} 
            alt={alt} 
            height={height} 
        />
    )
}

const Partners = () => {
    const logos = [
        { src: ukri, alt: 'UK Research and Innovation (UKRI)', height: 55 },
        { src: phab, alt: 'Phab', height: 50 },
        { src: cambridge_logo, alt: 'Cambridge University', height: 50 },
        { src: mencap, alt: 'Mencap', height: 60 },
        { src: mayor_of_london, alt: 'Mayor of London', height: 35 },
        { src: just4children, alt: 'Just 4 Children', height: 70 },
        { src: digital_health_london, alt: 'Digital Health London', height: 60 },
        { src: movement_centre, alt: 'Movement Centre', height: 60 },
        { src: differing_minds, alt: 'Differing Minds', height: 60 },
        //{ src: affinity_hub, alt: 'Affinity Hub', height: 70 },
        { src: children_in_motion, alt:'Children in Motion', height: 60 },
        { src: ept, alt: 'Empowering Parents Together (EPT)', height: 55 },
        { src: keen, alt: 'KEEN London', height: 50 },
        //{ src: samuels_charity, alt: "Samuel's Charity", height: 50 },
    ]
  
    const render = logos.map((props, index) => {
        return <Logo key={index} src={props.src} alt={props.alt} height={props.height} />
    });

    return (
        <div className='partners'>
            <div className='partners-text-div'>
                <p className='partners-text'>Supported by</p>
            </div>
            {/*
            <Looper speed="20" direction="right">
                <div className="partners-row">{render}</div>
            </Looper>
            */}
            <div className="partners-row">{render}</div>
        </div>
    )
}

export default Partners
