import React from 'react'
import { Link } from 'react-router-dom'

import './partnering.css'

import partnerships_family from '../../assets/partnerships_family.webp'
import arrow_right_dark from '../../assets/arrow_right_dark.webp'

const Partnering = () => {
    return (
        <div className='partnering'>
            <img
                loading='lazy'
                className='partnering-image'
                src={partnerships_family}
                alt='Partnerships'
                width={600}
            />
            <h1 className='partnering-title'>Partnering with Hibi</h1>
            <p className='partnering-text'>
                At Hibi, we’re working with forward thinking health, education and social care
                organisations to improve care outcomes and reduce system costs.
            </p>
            <Link className='partnering-button' to='/contact'>
                <div className='partnering-button-div'>
                    <p>Contact us</p>
                    <img loading='lazy' src={arrow_right_dark} alt='Arrow right' width={24} />
                </div>
            </Link>
        </div>
    )
}

export default Partnering
