import React, { useState } from 'react'
import './contact.css'

import arrow_right_dark from '../../assets/arrow_right_dark.webp'
import check_circle from '../../assets/check_circle.webp'

import { Navbar, Footer } from '../../components'

const Contact = () => {
    const [formStatus, setFormStatus] = useState('neutral')
    const [userMessage, setUserMessage] = useState('')

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const onFormSubmit = async () => {
        if (!(email && email !== '' && /^\w+([-.]?\w+)*@\w+([-.]?\w+)*([.]\w+)+$/.test(email))) {
            setFormStatus('failure')
            setUserMessage('Please enter a valid email and try again.')
            setTimeout(() => setFormStatus('neutral'), 5000)
            return
        }

        if (!(message && message !== '')) {
            setFormStatus('failure')
            setUserMessage('Message cannot be left empty. Please try again.')
            setTimeout(() => setFormStatus('neutral'), 5000)
            return
        }

        await fetch('https://pbcpkhhoy2ewvlq77ah7ppg7ue0vddld.lambda-url.eu-west-2.on.aws/', {
            method: 'POST',
            // Disable `mode` for production, enable for localhost testing
            mode: 'no-cors',
            headers: { 'Content-Type': 'application/json', accept: 'application/json' },
            body: JSON.stringify({
                firstName: firstName,
                lastName: lastName,
                email: email,
                message: message,
            }),
        })
            .then((response) => {
                setFormStatus('success')
                setUserMessage('Thanks! We’ll be in touch soon.')
                return
            })
            .catch((error) => {
                setFormStatus('failure')
                setUserMessage('Please try again in a few moments.')
                setTimeout(() => setFormStatus('neutral'), 5000)
                return
            })
    }
    return (
        <div className='Contact'>
            <Navbar />
            <div className='contact'>
                <h1 className='contact-title'>Contact us</h1>
                <p className='contact-description'>
                    We’d love to hear from you. Please get in touch with any comments, questions or
                    feedback.
                </p>
                <div className='contact-form'>
                    <div className='contact-form-input-row'>
                        <input
                            type='text'
                            placeholder='First name'
                            value={firstName}
                            onChange={(event) => setFirstName(event.target.value)}
                        />
                        <input
                            type='text'
                            placeholder='Last name'
                            value={lastName}
                            onChange={(event) => setLastName(event.target.value)}
                        />
                    </div>
                    <input
                        type='email'
                        placeholder='Your email'
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <textarea
                        placeholder='Your message'
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                    />
                </div>
                {formStatus === 'neutral' && (
                    <button
                        className='contact-button'
                        onClick={async () => await onFormSubmit()}
                        type='submit'
                    >
                        <div className='contact-button-div'>
                            <p>Send message</p>
                            <img
                                loading='lazy'
                                src={arrow_right_dark}
                                alt='Arrow right'
                                width={24}
                            />
                        </div>
                    </button>
                )}
                {formStatus === 'failure' && <p className='failure_text'>{userMessage}</p>}
                {formStatus === 'success' && (
                    <div className='success_message'>
                        <img
                            loading='lazy'
                            src={check_circle}
                            alt='Checkmark'
                            width={24}
                            height={24}
                        />
                        <p className='success_text'>{userMessage}</p>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    )
}

export default Contact
