import React from 'react'
import ReactPlayer from 'react-player'

import './story.css'

const Story = () => {
    return (
        <div className='story'>
            <div className='story-column'>
                <h1 className='story-title'>Our story</h1>
                <p className='story-text'>
                    We built Hibi having ourselves seen how fragmented and complex care systems can
                    be for families. Managing, coordinating & navigating care is overwhelming.
                    <br />
                    <br />
                    Today, we're left with towers of paper folders, long waits on telephone
                    helplines, and a whole lot of Google-ing. We founded Hibi with the mission to
                    make sure that all children and their families have the tools, resources and
                    support they need to receive the best care possible.
                </p>
            </div>

            <div className='video-player'>
                <div className='video-player-react'>
                    <ReactPlayer url='https://www.youtube.com/watch?v=rNVhorTdJwA' width='100%' />
                </div>
            </div>
        </div>
    )
}

export default Story
