import React from 'react'
import { Link } from 'react-router-dom'

import hibi_logo_with_title from '../../assets/hibi_logo_plus_title.webp'
import instagram from '../../assets/instagram.webp'
import facebook from '../../assets/facebook.webp'
import linkedin from '../../assets/linkedin.webp'
import './footer.css'

const Menu = () => (
    <>
        <p>
            <Link className='menu-link' to='/'>
                Home
            </Link>
        </p>
        <p>
            <Link className='menu-link' to='/about'>
                About
            </Link>
        </p>
        <p>
            <Link className='menu-link' to='/partnerships'>
                Partnerships
            </Link>
        </p>
        <p>
            <Link className='menu-link' to='/contact'>
                Contact
            </Link>
        </p>
    </>
)

const Footer = ({ isBottomFixed = false, hasBorderTop = true }) => {
    let className = 'footer'
    if (isBottomFixed) {
        className += ' footer-fixed-bottom'
    }
    if (hasBorderTop) {
        className += ' footer-border-top'
    }
    return (
        <div className={className}>
            <div className='footer-links'>
                <div className='footer-links_logo'>
                    <Link to='/'>
                        <img loading='lazy' src={hibi_logo_with_title} alt='Logo' />
                    </Link>
                </div>
                <div className='footer-links_container'>
                    <Menu />
                    <div className='footer-links_socials'>
                        <a href='https://www.linkedin.com/company/hibi'>
                            <img
                                loading='lazy'
                                src={linkedin}
                                width={24}
                                height={24}
                                alt='LinkedIn'
                            />
                        </a>
                        <a href='https://www.instagram.com/hibihealth/'>
                            <img
                                loading='lazy'
                                src={instagram}
                                width={24}
                                height={24}
                                alt='Instragram'
                            />
                        </a>
                        <a href='https://www.facebook.com/people/Hibi/100089603070874/'>
                            <img
                                loading='lazy'
                                src={facebook}
                                width={24}
                                height={24}
                                alt='Facebook'
                            />
                        </a>
                    </div>
                    <div className='footer-copyright'>
                        <p>© 2024 Hibi Health Ltd</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
