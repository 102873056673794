import React from 'react'

import { formatDA } from '../../utils'

const PostHeader = ({ title, coverImage, slug, date, author }) => {
    return (
        <>
            {coverImage && (
                <div className='mb-1 sm:mx-0 lg:w-full mt-5 flex items-center justify-center lg:mt-10 xl:mt-20 lg:hidden '>
                    <img
                        src={coverImage}
                        alt={`Cover for ${title}`}
                        className='w-full h-auto lg:h-full lg:object-contain lg:object-center rounded-lg'
                    />
                </div>
            )}
            <h1 className='mb-4 mt-8 md:mt-10 lg:mt-10 xl:mt-16 2xl:mt-20 font-GTSectra text-[#1E4D32] text-[44px] font-bold leading-tight text-left xl:text-[64px] md:leading-none '>
                {title}
            </h1>
            <p className='mt-3 lg:mt-6 font-Inter  text-[#757575] text-[18px] font-normal leading-tight  text-left md:leading-none '>
                by {author}
            </p>
            <div className='border-t border-[#D0D1C9] border-solid w-1/4 my-5 lg:mb-8 lg:w-1/12 lg:mt-8'></div>
            <div className='mx-auto max-w-2xl lg:mt-10 xl:mt-22'>
                <p className='mb-6 text-[18px] text-[#757575] font-Inter'>{formatDA(date)}</p>
            </div>
        </>
    )
}

export default PostHeader
