import React, { useEffect, useState } from 'react'
import BlogCard from './BlogCard'
import client from '../../client'
import { formatDA } from '../../utils'

const TopBlogs = () => {
    const [posts, setPosts] = useState([])

    useEffect(() => {
        client
            .fetch(
                `*[_type == "post"] {
                    title,
                    slug,
                    categories[]->{
                        title
                      },
                    publishedAt,
                    mainImage {
                        asset -> {
                            _id,
                            url
                        },
                        alt,
                    },
                    "name": author->name,
                } | order(publishedAt desc) [0..5]`,
            )
            .then((data) => setPosts(data))
            .catch(console.error)
    }, [])

    return (
        <div className='container max-w-screen-xl mx-auto space-y-6 sm:space-y-12'>
            <div className='grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2 gap-10'>
                {posts.map((post, index) => (
                    <BlogCard
                        key={index}
                        category={post.categories[0]?.title}
                        title={post.title}
                        image={post.mainImage?.asset?.url}
                        date={formatDA(post.publishedAt)}
                        extraStyles='sm:w-1/2'
                        slug={post.slug?.current}
                        authorName={post.name}
                    />
                ))}
            </div>
        </div>
    )
}

export default TopBlogs
