import React from 'react'
import { Link } from 'react-router-dom'

import './goto.css'

import arrow_right from '../../assets/arrow_right.webp'

const GoTo = ({ 
    title = 'Media resources', 
    description = 'Discover the latest brand assets and product images in our comprehensive media kit. Access everything you need to accurately represent Hibi in your content.', 
    buttonTitle = 'See our media kit', 
    to = '/media-kit'
}) => {
    return (
        <div className='goto'>
            <div className='goto-column'>
                <h1 className='goto-title'>
                    {title}
                </h1>
            </div>
            <div className='goto-column'>
                <p className='goto-text'>
                    {description}
                </p>
                <Link to={to}>
                    <div className='goto-button-div'>
                        <p>{buttonTitle}</p>
                        <img loading='lazy' src={arrow_right} alt='Arrow right' width={24} />
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default GoTo
