import React, { useEffect, useState } from 'react'
import CategoryButton from './CategoryButton'
import client from '../../client'
import BlogsDisplay from './BlogsDisplay'

const CategoryPicker = () => {
    const [categories, setCategories] = useState([])
    const [activeCategory, setactiveCategory] = useState('All')

    useEffect(() => {
        client
            .fetch(
                `*[_type == "category"]{
                    _id,
                    title,
                  }`,
            )
            .then((data) => setCategories(data))
            .catch(console.error)
    }, [])

    return (
        <>
            <div className='flex flex-wrap gap-3 justify-center max-w-screen-xl mx-auto'>
                <CategoryButton
                    text='All'
                    activeCategory={activeCategory}
                    setactiveCategory={setactiveCategory}
                />
                {categories.length > 0 &&
                    categories.map((category) => (
                        <CategoryButton
                            text={category.title}
                            activeCategory={activeCategory}
                            setactiveCategory={setactiveCategory}
                            key={category._id}
                        />
                    ))}
            </div>
            <BlogsDisplay activeCategory={activeCategory} />
        </>
    )
}

export default CategoryPicker
