import React from 'react'
import BlogHomePage from '../../components/blog/BlogHomePage'
import { Footer, Navbar } from '../../components'

const Blog = () => {
    return (
        <div className='Home'>
            <Navbar />
            <BlogHomePage />
            <Footer />
        </div>
    )
}

export default Blog
