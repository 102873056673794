import React from 'react'
import { Value } from '../../components'
import './values.css'

const Values = ({ titleComponent, cardImgSources, cardTitles, cardSubtitles }) => {
    return (
        <div className='values'>
            {titleComponent}
            <div className='values-row'>
                {cardTitles.map((cardTitle, index) => (
                    <Value
                        key={cardTitle}
                        imgSource={cardImgSources[index]}
                        title={cardTitle}
                        subtitle={cardSubtitles[index]}
                    />
                ))}
            </div>
        </div>
    )
}

export default Values
