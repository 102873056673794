import React, { useState } from 'react'
import './BlogHomePage.css'
import CategoryPicker from './CategoryPicker'
import TopBlogs from './TopBlogs'
import search from '../../assets/search_gray.png'
import Searchbar from './Searchbar'

const BlogHomePage = () => {
    const [searchMode, setsearchMode] = useState(false)

    return searchMode ? (
        <>
            <Searchbar setsearchMode={setsearchMode} />
        </>
    ) : (
        <>
            <div className='max-w-screen-xl mx-auto flex flex-row items-center'>
                <p className='font-GTSectra container text-4xl xl:text-5xl flex items-center mb-10 mt-10'>
                    Featured
                </p>
                <img
                    src={search}
                    className='h-10 hover:cursor-pointer'
                    alt='Search Icon'
                    onClick={() => setsearchMode(!searchMode)}
                />
            </div>
            <TopBlogs />
            <h2 className=' max-w-screen-xl mx-auto font-GTSectra text-4xl xl:text-5xl font-normal items-center justify-center mb-10 mt-10'>
                Explore posts from the following categories
            </h2>
            <CategoryPicker />
        </>
    )
}

export default BlogHomePage
