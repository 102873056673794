import React, { useState, useEffect } from 'react'

import { Tag, OptimizedImage, MailchimpForm } from '../../components'

import googlePlayStoreBadge from '../../assets/google_play_badge.webp'
import appleAppStoreBadge from '../../assets/apple_app_store.webp'
import arrow_right_dark from '../../assets/arrow_right_dark.webp'
import check_circle from '../../assets/check_circle.webp'
import landing_screens from '../../assets/landing_screens.webp'
import landing_screens_low from '../../assets/landing_screens_low.webp'
import landing_screens_parent from '../../assets/landing_screens_parent.webp'
import landing_screens_parent_low from '../../assets/landing_screens_parent_low.webp'

import './header.css'

// Kill switch to hide app store banner
const showAppStoreBanner = true

const Header = ({ context = null }) => {
    const [formStatus, setFormStatus] = useState('neutral')
    const [userMessage, setUserMessage] = useState('')
    const [email, setEmail] = useState('')
    const [showMailchimpForm, setShowMailchimpForm] = useState(context && context === 'signup-to-newsletter' ? true : false)

    // Set country code (used with Apple app store link)
    const [countryCode, setCountryCode] = useState('gb')
    useEffect(() => {
        const getCountryFromIPAsync = async () => {
            await fetch('https://ipapi.co/json/')
                .then((result) => result.json())
                .then((response) => {
                    if (response.country_name === 'United Kingdom') {
                        setCountryCode('gb')
                    } else if (response.country_name === 'United States') {
                        setCountryCode('us')
                    } else {
                        setCountryCode('gb')
                    }
                })
        }

        getCountryFromIPAsync()
    }, [])

    const onFormSubmit = async () => {
        const isEmailValid = email && email !== '' && /^\w+([-.]?\w+)*@\w+([-.]?\w+)*([.]\w+)+$/.test(email)

        if (isEmailValid) {
            await fetch('https://4ysxvsiuabr474g6nfgzz3d6s40ohlxc.lambda-url.eu-west-2.on.aws/', {
                method: 'POST',
                // Disable `mode` for production, enable for localhost testing
                mode: 'no-cors',
                headers: { 'Content-Type': 'application/json', accept: 'application/json' },
                body: JSON.stringify({ email: email }),
            })
                .then((response) => {
                    setFormStatus('success')
                    setUserMessage('Thanks! We’ll be in touch soon.')
                    return
                })
                .catch((error) => {
                    setFormStatus('failure')
                    setUserMessage('Please try again in a few moments.')
                    setTimeout(() => setFormStatus('neutral'), 5000)
                    return
                })
        } else {
            setFormStatus('failure')
            setUserMessage('Please enter a valid email and try again.')
            setTimeout(() => setFormStatus('neutral'), 5000)
            return
        }
    }

    // Get UTM / attribution metadata
    const source = document.referrer ? new URL(document.referrer).hostname : 'direct'
    const urlParams = new URLSearchParams(window.location.search)
    const medium = urlParams.get('utm_medium')
    const campaign = urlParams.get('utm_campaign')

    const googlePlayStoreURL = `https://play.google.com/store/apps/details?id=com.hibi.app&utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`
    const appleAppStoreURL = `https://apps.apple.com/${countryCode}/app/hibi-health/id6448462377?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`

    const primaryWho = context === 'parent' ? `your parent's care` : context === 'loved-one' ? `your loved one's care` : `your child's care`
    const secondaryWho = context === 'parent' ? `families` : context === 'loved-one' ? `families` : `families of children`

    return (
        <>
            {showMailchimpForm && <MailchimpForm setShowForm={setShowMailchimpForm} />}
            <div className='header section__vertical-padding' id='home' style={{ opacity: showMailchimpForm ? 0.3 : 1.0, filter: showMailchimpForm ? 'blur(4px)' : undefined }} onClick={() => showMailchimpForm && setShowMailchimpForm(false)}>
                <div className='header-content'>
                    {!showAppStoreBanner && <Tag title='Early access' />}
                    <h1 className='primary_text'>
                    Simplify & centralise <span style={{ fontFamily: 'GTSectraItalic' }}>{primaryWho}</span>
                    </h1>
                    <p className='secondary-text'>
                    Hibi is an app to support {secondaryWho} with developmental differences and health conditions, providing access to care management tools and expert guidance.
                    </p>
                    {showAppStoreBanner && (
                        <div className='app-store-info'>
                            <div className='app-store-badges'>
                                <a href={appleAppStoreURL} target='_blank' rel='noopener noreferrer'>
                                    <img
                                        loading='lazy'
                                        src={appleAppStoreBadge}
                                        alt='Apple App Store'
                                        className='apple-badge'
                                    />
                                </a>
                                <a href={googlePlayStoreURL} target='_blank' rel='noopener noreferrer'>
                                    <img
                                        loading='lazy'
                                        src={googlePlayStoreBadge}
                                        alt='Google Play Store'
                                        className='google-badge'
                                    />
                                </a>
                            </div>
                            <p className='secondary-text' style={{ fontSize: 16}}>Join 10,000+ families on Hibi today, for free. You can also <span style={{ color: '#1E4C32', fontWeight: 500, cursor: 'pointer' }} onClick={() => setShowMailchimpForm(true)}>subscribe to our newsletter.</span></p>
                        </div>
                    )}

                    {!showAppStoreBanner && (
                        <>
                            <div className='header-content__input'>
                                <input
                                    type='email'
                                    placeholder='Your email'
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </div>
                            {formStatus === 'neutral' && (
                                <button onClick={async () => await onFormSubmit()} type='submit'>
                                    <div className='header-button-div'>
                                        <p>Sign up</p>
                                        <img
                                            loading='lazy'
                                            src={arrow_right_dark}
                                            alt='Arrow right'
                                            width={24}
                                        />
                                    </div>
                                </button>
                            )}
                            {formStatus === 'failure' && <p className='failure_text'>{userMessage}</p>}
                            {formStatus === 'success' && (
                                <div className='success_message'>
                                    <img
                                        loading='lazy'
                                        src={check_circle}
                                        alt='Checkmark'
                                        width={24}
                                        height={24}
                                    />
                                    <p className='success_text'>{userMessage}</p>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className='header-image'>
                    <OptimizedImage 
                        loading='lazy' 
                        src={context === 'parent' ? landing_screens_parent : landing_screens} 
                        lowSrc={context === 'parent' ? landing_screens_parent_low : landing_screens_low}
                        alt='Hibi App Screens' 
                    />
                </div>
            </div>
        </>
    )
}

export default Header
