import { useState, useEffect } from 'react'

export function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height,
    }
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
}

export function formatDA(inputDate) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    const date = new Date(inputDate)
    return date.toLocaleDateString('en-US', options)
}

    // Flag to enable custom quiz form
    export const customQuizFormEnabled = false
