import React from 'react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { hydrate, render } from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { isbot } from 'isbot'

import App from './App'
import './index.css'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

const app = (
    <HelmetProvider>
        <StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </StrictMode>
    </HelmetProvider>
)

if (isbot(navigator.userAgent)) {
    // Serve pre-rendered files to crawlers
    if (rootElement.hasChildNodes()) {
        hydrate(app, rootElement)
    } else {
        render(app, rootElement)
    }
} else {
    // Serve the regular React app to actual users
    root.render(app)
}