import React from 'react'
import { Link } from 'react-router-dom'

import './impact.css'

import arrow_right from '../../assets/arrow_right.webp'

const Impact = () => {
    return (
        <div className='impact'>
            <div className='impact-column'>
                <h1 className='impact-title'>
                    Make an <span style={{ fontFamily: 'GTSectraItalic' }}>impact</span>
                </h1>
            </div>
            <div className='impact-column'>
                <p className='impact-text'>
                    If you're an organisation working with children with additional needs and their
                    families, we'd love to talk.
                </p>
                <Link to='/contact'>
                    <div className='impact-button-div'>
                        <p>Contact us</p>
                        <img loading='lazy' src={arrow_right} alt='Arrow right' width={24} />
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Impact
