import React from 'react'
import { Link } from 'react-router-dom'

import './cta.css'

const CTA = ({ buttonText = 'Try the app', to='https://onelink.to/gn5zbu', ctaButtonWidth = 128 }) => {
    return (
        <Link className='cta-button' to={to}>
            <button className='cta-button-div' style={{ width: ctaButtonWidth }}>
                <p style={{ textAlign: 'center', fontFamily: 'Inter', textTransform: 'initial' }}>
                    {buttonText}
                </p>
            </button>
        </Link>
    )
}

export default CTA
