import React from 'react'
import './value.css'

const Value = ({ imgSource, title, subtitle }) => {
    return (
        <div className='value'>
            <img loading='lazy' src={imgSource} alt={title} width={48} height={48} />
            <p className='value-title'>{title}</p>
            <p className='value-subtitle'>{subtitle}</p>
        </div>
    )
}

export default Value
